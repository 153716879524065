import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import img from '../images/industry-sector/team_industry.svg'
import IndustryTemplateLayout from '../components/IndustrySector/IndustryTemplateLayout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import IndustryLeftCol from '../components/IndustrySector/IndustryLeftCol'
import IndustryRightCol from '../components/IndustrySector/IndustryRightCol'
import IndustryDescription from '../components/IndustrySector/IndustryDescription'
import logo from '../images/homepage/popin-anim/architecture.svg'
import CaseStudyContent from '../components/CaseStudy/CaseStudyContent'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Case Studies',
    url: '/case-studies',
  },
  {
    title: 'Company name',
    url: '/case-study-template',
  },
]

const CaseStudyTemplate = () => {
  return (
    <Layout
      activeLink="/case-studies"
      title="R&D Case study template"
      description="R&D Case study template"
    >
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <IndustryTemplateLayout>
        <IndustryLeftCol
          category="Case Studies"
          title="Company name"
          backLink="/case-studies"
        />
        <IndustryRightCol>
          <IndustryDescription title="Company Name" />
          <CaseStudyContent
            heading="Our approach"
            paragraph="Do your projects involve high quality type material finishes? Do you design bespoke products for the aerospace industry using different types of materials? Are you using existing design processes for manufacturing techniques on the project but in a new or novel way? Do your projects involve physical testing or prototyping for instance impact resistance and strength?"
            imgDesktop={img}
            imgMobile={img}
            alt="Aeroplane"
            logo={logo}
            content={[
              <div>
                {/* <p>
                  {' '}
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Id cursus metus aliquam eleifend mi. Ipsum a arcu cursus vitae
                  congue mauris rhoncus. Sem viverra aliquet eget sit amet
                  tellus cras adipiscing. Dolor sit amet consectetur adipiscing
                  elit ut aliquam purus. Leo a diam sollicitudin tempor id.
                  Ridiculus mus mauris vitae ultricies leo integer.
                </p>
                <h6>Our approach</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Id cursus metus aliquam eleifend mi. Ipsum a arcu cursus vitae
                  congue mauris rhoncus. Sem viverra aliquet eget sit amet
                  tellus cras adipiscing. Dolor sit amet consectetur adipiscing
                  elit ut aliquam purus. Leo a diam sollicitudin tempor id.
                  Ridiculus mus mauris vitae ultricies leo integer.
                </p>
                <h6>Outcome</h6>
                <b>Orci phasellus egestas tellus rutrum tellus</b>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Id cursus metus aliquam eleifend mi. Ipsum a arcu cursus vitae
                  congue mauris rhoncus. Sem viverra aliquet eget sit amet
                  tellus cras adipiscing. Dolor sit amet consectetur adipiscing
                  elit ut aliquam purus. Leo a diam sollicitudin tempor id.
                  Ridiculus mus mauris vitae ultricies leo integer.
                </p>
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </li>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </li>
                </ul>
                <p>
                  Leo a diam sollicitudin tempor id. Ridiculus mus mauris vitae
                  ultricies leo integer. Leo in vitae turpis massa sed. Mauris
                  pellentesque pulvinar pellentesque habitant morbi tristique
                  senectus et. Ridiculus mus mauris vitae ultricies leo integer.
                  Orci phasellus egestas tellus rutrum tellus. Viverra mauris in
                  aliquam sem fringilla ut morbi. Nisi vitae suscipit tellus
                  mauris a diam maecenas sed enim. Eget egestas purus viverra
                  accumsan in nisl nisi scelerisque.
                </p> */}
              </div>,
            ]}
          />
        </IndustryRightCol>
      </IndustryTemplateLayout>
      <PromotionBanner />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default CaseStudyTemplate
